<template>
  <section id="rekapituliasi-persetujuan-direktur">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <validation-observer
            v-if="show"
            ref="formSurat"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Surat Rekomendasi Untuk Surat No. {{ clearanceData.letter_number }}
                </h5>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Lembaga'"
                  label="Lembaga"
                  label-for="vi-instansi_name"
                >
                  <b-form-input
                    id="vi-instansi_id"
                    v-model="clearanceData.instansi_id"
                    hidden
                    required
                    name="instansi_id"
                  />
                  <b-form-input
                    id="vi-instansi_name"
                    v-model="clearanceData.instansi_name"
                    required
                    disabled
                    name="instansi_name"
                    placeholder="Nama Lembaga"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Tanggal Surat Dibuat'"
                  label="Tanggal Surat"
                  label-for="vi-letter_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Surat"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="vi-letter_date"
                      v-model="letter_date"
                      required
                      name="letter_date"
                      placeholder="Tanggal Surat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Nomor Surat Kemenkominfo'"
                  label="Nomor Surat Kemenkominfo"
                  label-for="vi-letter_number_kominfo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Surat Kemenkominfo"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-letter_number_kominfo"
                      v-model="letter_number_kominfo"
                      required
                      name="letter_number_kominfo"
                      placeholder="Nomor Surat Kemenkominfo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Nomor Surat Kemenpanrb'"
                  label="Nomor Surat Kemenpanrb"
                  label-for="vi-letter_number_panrb"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Surat Kemenpanrb"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-letter_number_panrb"
                      v-model="letter_number_panrb"
                      required
                      name="letter_number_panrb"
                      placeholder="Nomor Surat Kemenpanrb"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="yearBudget !== '2023'"
                md="6"
              >
                <b-form-group
                  v-b-tooltip.hover.top="'Nomor Surat Bappenas'"
                  label="Nomor Surat Bappenas"
                  label-for="vi-letter_number_bappenas"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Surat Bappenas"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-letter_number_bappenas"
                      v-model="letter_number_bappenas"
                      required
                      name="letter_number_bappenas"
                      placeholder="Nomor Surat Bappenas"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- table -->
              <b-col cols="12">
                <h6>Daftar Item Belanja Yang Direkomendasikan</h6>
                <vue-good-table
                  class="mt-1"
                  :columns="columns2"
                  :rows="rowSurat"
                  :pagination-options="{
                    enabled: false,
                  }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'nama'"
                      class="text-nowrap"
                    >
                      <b-badge
                        variant="light-secondary"
                      >
                        {{ props.row.domain }}
                      </b-badge>
                      <span class="d-block">
                        {{ props.row.nama }}
                      </span>
                    </span>
                    <span v-else-if="props.column.field === 'anggaran'">
                      {{ Number(props.row.anggaran).toLocaleString() }}
                    </span>
                    <!-- Lihat Rekomendasi Direktur -->
                    <div v-else-if="props.column.field == 'rekomendasi'">
                      <div v-if="props.row.rekomendasi.continue.length !== 0">
                        <h5>Dilanjutkan</h5>
                        <p
                          v-for="(reko, keyd) in props.row.rekomendasi.continue"
                          :key="keyd"
                        >
                          {{ reko }}
                        </p>
                      </div>
                      <div v-if="props.row.rekomendasi.postpone.length !== 0">
                        <h5>Tidak Dilanjutkan</h5>
                        <p
                          v-for="(reko, keyd) in props.row.rekomendasi.postpone"
                          :key="keyd"
                        >
                          {{ reko }}
                        </p>
                      </div>
                    </div>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="props.row.rekomendasi.continue.length !== 0 || props.row.rekomendasi.postpone.length !== 0"
                        variant="warning"
                        size="sm"
                        class="m-25"
                        @click="removeFromSurat(props.row, props.index)"
                      >
                        Hapus Dari Surat
                      </b-button>
                    </span>

                  </template>
                </vue-good-table>
              </b-col>
              <b-col
                v-if="errorStat"
                cols="12"
                class="mt-1"
              >
                <b-alert
                  variant="danger"
                  show
                >
                  <div class="alert-body">
                    Error Found: {{ errorMsg }}
                  </div>
                </b-alert>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-button
                  type="submit"
                  variant="success"
                  size="sm"
                  @click.prevent="simpanSurat"
                >
                  Buat Surat
                </b-button>
                <b-button
                  v-if="dataSaved"
                  variant="flat-success"
                  size="sm"
                  class="mt-0 ml-50"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                  /> Surat Berhasil Disimpan
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <!-- table -->
          <h4 class="mt-2">
            Daftar Item Belanja Dalam Surat Permohonan
          </h4>
          <vue-good-table
            class="mt-2"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'nama'"
                class="text-wrap"
              >
                <b-badge
                  variant="light-secondary"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'id'">
                {{ props.row.id }}
              </span>
              <span v-else-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <!-- Lihat Rekomendasi Direktur -->
              <div v-else-if="props.column.field == 'rekomendasi'">
                <div v-if="props.row.rekomendasi.continue.length !== 0">
                  <h5>Dilanjutkan</h5>
                  <p
                    v-for="(reko, keyd) in props.row.rekomendasi.continue"
                    :key="keyd"
                  >
                    {{ reko }}
                  </p>
                </div>
                <div v-if="props.row.rekomendasi.postpone.length !== 0">
                  <h5>Tidak Dilanjutkan</h5>
                  <p
                    v-for="(reko, keyd) in props.row.rekomendasi.postpone"
                    :key="keyd"
                  >
                    {{ reko }}
                  </p>
                </div>
              </div>
              <span v-else-if="props.column.field === 'action'">
                <b-badge
                  v-if="props.row.exist === 'YES'"
                  variant="light-warning"
                  class="mb-50"
                >Sudah pernah ditambahkan<br>di surat sebelumnya
                </b-badge>
                <b-button
                  v-if="props.row.status_id > 79"
                  variant="primary"
                  size="sm"
                  class="m-25"
                  @click="addToSurat(props.row, props.index)"
                >
                  Tambah ke Surat
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->

    </b-row>

  </section>

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BBadge, BRow, BFormDatepicker, BButton, BCol, BCard, BPagination, BFormGroup,
  BFormInput, BFormSelect, VBTooltip, VBModal, BAlert,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import moment from 'moment'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BFormDatepicker,
    BButton,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,

  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 11 || role > 70) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      yearBudget: localStorage.getItem('tahunAnggaran'),
      required,
      today,
      show: false,
      userData: getUserData(),
      clearance_id: '',
      clearanceData: '',
      listClearance: '',
      pageLength: 10,
      columns: [
        {
          thClass: 'text-center',
          label: 'No',
          field: 'id',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Unit Kerja',
          field: 'unit_kerja',
          width: '175px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Nama Kegiatan',
          field: 'nama_kegiatan',
          width: '175px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Item Belanja',
          width: '175px',
          field: 'nama',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Anggaran',
          field: 'anggaran',
          type: 'number',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Rekomendasi & Catatan',
          field: 'rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      columns2: [
        {
          thClass: 'text-center',
          label: 'No',
          field: 'id',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Unit Kerja',
          field: 'unit_kerja',
          width: '175px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Nama Kegiatan',
          field: 'nama_kegiatan',
          width: '175px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Item Belanja',
          width: '175px',
          field: 'nama',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Anggaran',
          field: 'anggaran',
          type: 'number',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Rekomendasi & Catatan',
          field: 'rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rowSurat: [],
      appList: [],
      infraList: [],
      dataList: [],
      nonList: [],
      instansi_name: '',
      letter_date: '',
      letter_number_kominfo: '',
      letter_number_panrb: '',
      letter_number_bappenas: '',
      number: 0,
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
    }
  },
  created() {
    this.clearance_id = this.$route.params.id
    this.$http.get('/surat/get', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.clearance_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.listClearance = res.data.data
          this.prepData()
        }
      })
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.clearance_id,
      },
    })
      .then(res => {
        this.clearanceData = res.data.data
        this.letter_date = moment(new Date(this.today)).format('YYYY-MM-DD')
        this.show = true
      })
  },
  methods: {
    statusVariant(status) {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: {
          title: 'Dilanjutkan',
          class: 'text-success',
        },
        2: {
          title: 'Tidak Dilanjutkan',
          class: 'text-danger',
        },
        3: {
          title: 'Dispute',
          class: 'light-warning',
        },
        /* eslint-enable key-spacing */
      }

      return statusColor[status]
    },
    addToSurat(value, id) {
      this.rowSurat.push(value)
      this.$delete(this.rows, id)
      if (value.aplikasi_id) {
        this.appList.push(value.aplikasi_id)
      }
      if (value.infrastruktur_id) {
        this.infraList.push(value.infrastruktur_id)
      }
      if (value.data_informasi_id) {
        this.dataList.push(value.data_informasi_id)
      }
      if (value.non_teknis_id) {
        this.nonList.push(value.non_teknis_id)
      }
    },
    removeFromSurat(value, id) {
      this.rows.push(value)
      this.$delete(this.rowSurat, id)
      if (value.aplikasi_id) {
        this.$delete(this.appList, this.appList.indexOf(value.aplikasi_id))
      }
      if (value.infrastruktur_id) {
        this.$delete(this.infraList, this.infraList.indexOf(value.infrastruktur_id))
      }
      if (value.data_informasi_id) {
        this.$delete(this.dataList, this.dataList.indexOf(value.data_informasi_id))
      }
      if (value.non_teknis_id) {
        this.$delete(this.nonList, this.nonList.indexOf(value.non_teknis_id))
      }
    },
    simpanSurat() {
      this.$refs.formSurat.validate()
        .then(success => {
          if (success) {
            if (this.rowSurat.length === 0) {
              this.errorStat = true
              this.errorMsg = 'Data Aplikasi / Infrastruktur Yang Direkomendasikan Tidak Boleh Kosong'
            } else {
              this.submitSurat()
            }
          } else {
            this.errorStat = true
            this.errorMsg = 'Terdapat Kesalahan Validasi, Periksa Input Anda'
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.message
        })
    },
    submitSurat() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        clearance_id: this.clearance_id,
        tanggal_surat: this.letter_date,
        no_surat_kominfo: this.letter_number_kominfo,
        no_surat_panrb: this.letter_number_panrb,
      }
      if (this.letter_number_bappenas) {
        params.no_surat_bapenas = this.letter_number_bappenas
      }
      if (this.appList.length !== 0) {
        params.app_list = this.appList
      }
      if (this.infraList.length !== 0) {
        params.infra_list = this.infraList
      }
      if (this.dataList.length !== 0) {
        params.data_list = this.dataList
      }
      if (this.nonList.length !== 0) {
        params.non_teknis_list = this.nonList
      }

      this.$http.post(`/generate/letter?token=${localStorage.getItem('userToken')}`, params)
        .then(resp => {
          if (resp.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.dataSaved = true
            this.errorStat = false
            this.errorMsg = ''

            setTimeout(() => {
              this.dataSaved = false
              this.$router.replace({ name: 'list-surat-rekomendasi', params: { id: this.clearance_id } })
            }, 2000)
          } else {
            this.errorStat = true
            this.errorMsg = (resp.data.error) ? resp.data.error : resp.data.message
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = (error.response.data.error) ? error.response.data.error : error.response.data.message
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    prepData() {
      this.rows = []
      if (this.listClearance.length !== 0) {
        this.listClearance.map(value => {
          if (value.aplikasi.length !== 0) {
            value.aplikasi.map(apps => {
              this.number += 1
              const appData = apps
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Aplikasi'

              this.rows.push(appData)
              return true
            })
          }
          if (value.infrastruktur.length !== 0) {
            value.infrastruktur.map(infs => {
              this.number += 1
              const appData = infs
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Infrastruktur'

              this.rows.push(appData)
              return true
            })
          }
          if (value.domain_data.length !== 0) {
            value.domain_data.map(infs => {
              this.number += 1
              const appData = infs
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Data'

              this.rows.push(appData)
              return true
            })
          }
          if (value.non_teknis.length !== 0) {
            value.non_teknis.map(infs => {
              this.number += 1
              const appData = infs
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Non Teknis'

              this.rows.push(appData)
              return true
            })
          }
          return true
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
